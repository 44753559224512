@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&family=Metrophobic&family=Mitr&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  overflow: hidden;
}

.App, html, body, #root {
  height: 100vh;
  width: 100vw;
  --dark-1: #0a0947;
  --dark-2: #18177d;
  --light-1: #ffffff;
  --light-2: #A09AAC;
  --accent-green: #3a38f5;
  --accent-yellow: #F2C641;
  --accent-red: #F25252;
  --accent-pink: #ff73e5;
}

.App {
  text-align: center;
  font-family: 'Maven Pro', sans-serif;
  text-shadow: 3px 2px 2px var(--dark-1);
  font-weight: bold;

  color: var(--light-1);
  background: var(--dark-1);
}

.App-header {
  width: 100%;
  height: 4em;
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  font-family: 'Mitr', sans-serif;
  font-weight: 600;
  color: white;
}

.done-header {
  font-family: 'Mitr', sans-serif;
  font-weight: 600;
}


.mute-button {
  opacity: 0.8;
  cursor: pointer;
  position: absolute;
  right: 3em;
}
.mute-button:hover {
  opacity: 1;
}

.question-button {
  opacity: 0.8;
  cursor: pointer;
  position: absolute;
  right: 1em;
}
.question-button:hover {
  opacity: 1;
}

.App-body {
  height: calc(100% - 4em - 4em);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4em 0;
  text-align: center;
  align-items: center;
}

.screenshot-area {
  width: fit-content;
  position: relative;
}

.subtitle {
  font-size: 1.65em;
  margin-bottom: 2em;
  font-weight: 700;
  font-family: 'metrophobic', sans-serif;
}

.content {
  max-width: 100vw;
  max-height: 80vh;

  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-top:    4px solid var(--accent-green);
  border-right:  8px solid var(--accent-green);
  border-left:   3px solid var(--accent-green);
  border-bottom: 13px solid var(--accent-green);
  border-radius: 0.7em;

  transition: height 0.8s ease-in, width 0.8s ease-in;
}

.App-footer {
  width: 100%;
  height: 4em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  color: var(--light-2);
  background: var(--dark-2);
}

.code-btn {
  @apply text-white font-bold py-2 px-2 mt-10 cursor-pointer rounded;
  background-color: var(--dark-1);
}

.image-btn {
  cursor: pointer;
  border-radius: 8px;
  background-size: cover;
}

.sharer-btn {
  cursor: pointer;
}
.sharer-btn:hover {
  opacity: 0.8;
}

/* ANIMATIONS */
.textanimation-enter {
  opacity: 0;
  transform: scale(0.9);
}
.textanimation-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 700ms, transform 700ms;
}
.textanimation-exit {
  opacity: 1;
}
.textanimation-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 700ms, transform 700ms;
}

.slideanimation-enter {
  opacity: 0;
  transform: scale(0.9);
}
.slideanimation-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 700ms, transform 700ms;
}
.slideanimation-exit {
  opacity: 1;
}
.slideanimation-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 700ms, transform 700ms;
}

.circle {
  font-family: Verdana;
  overflow: hidden;
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.circle-fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  color: black;
}

.circle-overlay {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: white;
  animation: circle-overlay-anim 7s forwards;
  transform-origin: 0 0;
}

@keyframes circle-overlay-anim {
  100% {
    transform: scaleY(0);
  }
}
